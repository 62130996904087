import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Authenticate from "./components/Authenticate";
import AuthWrapper from "./components/Wrapper/AuthWrapper";
import Navigation from "./components/Navigation/Navigation";
import SideBar from "./components/SideBar/SideBar";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_COOKIE_DOMAIN,
  AUTH0_DOMAIN,
} from "./utils/path";
import SocialLogin from "./Pages/TokenManagementSystem/SocialLogin";
import SelectedAdAccountWrapper from "Pages/TokenManagementSystem/SelectAdAccounts/SelectedAdAccountWrapper";
import TMSWrapper from "./Pages/TokenManagementSystem/TMSWrapper";
import { CompatRouter, useSearchParams } from "react-router-dom-v5-compat";
import { Userpilot } from "userpilot";
import ThreeDGraphNewTab from "./Pages/TargetingAIPlaygroundBeta/ReactFlowComponents/ThreeDGraphNewTab";
import { TaiContextProvider } from "Pages/TargetingAIPlaygroundBeta/TaiContextProvider";
import FacebookReview from "Pages/CreativeAI/Facebook/Review/FacebookReview";
import GoogleReview from "Pages/CreativeAI/GsaiPlayground/Review/GoogleReview";
import PasswordSuccessful from "Pages/OnBoarding/PasswordSuccessful";
import PasswordExpired from "Pages/OnBoarding/PasswordExpired";
import LoginSuccessful from "./Pages/OnBoarding/LoginSuccessful";
import GoogleAnalytics from "Pages/ThirdPartyAttribution/Components/GoogleAnalytics";
import Redirection from "common/Redirection";

if (window.location?.hostname !== "localhost") document.domain = "pixis.ai";
const Routes = ({ open, setOpen }: any) => {

  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const isViewedFromBetaCai = searchParams.get("caisource");

  return (
    <div className="wrapper container-fluid h-100" style={{background : isViewedFromBeta ? '#ffffff' : ''}}>
      <div className="row gx-5">
        <div style={{ width: "5%", display : isViewedFromBeta ? 'none' : '' }}>
          <SideBar open={open} setOpen={setOpen} />
        </div>
        <div className={`px-4 pb-4 d-flex flex-column ${isViewedFromBeta?'right-container-beta':'right-container-legacy'}`} style={{background: isViewedFromBetaCai?'#f8f9fd':''}}>
          <Navigation />
        </div>
      </div>
    </div>
  );
};

Userpilot.initialize("NX-329d7b02");

type AppProps = unknown;
const App: React.FC<AppProps> = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Userpilot.reload();
  }, []);

  return (
    <Router>
      <CompatRouter>
        <Switch>
          <Route path="/google-analytics" component={GoogleAnalytics} />
          <Route
            // path="/review/cai/facebook/:urlHash"
            path="/review/cai/meta/:urlHash"
            exact
            component={FacebookReview}
          />
          <Route
            path="/review/cai/google/:urlHash"
            exact
            component={GoogleReview}
          />
          <Route
            path="/password/successfull"
            exact
            component={PasswordSuccessful}
          />
          <Route path="/password/expired" exact component={PasswordExpired} />

          <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizationParams={{
              audience: AUTH0_AUDIENCE,
              scope: "openid profile email offline_access",
              redirect_uri: window.location.origin,
            }}
            cookieDomain=".pixis.ai"
          >
            <Switch>
              <Route path="/cohort-graph" exact component={ThreeDGraphNewTab} />
              <Route path="/bing-redirection" exact component={Redirection} />
              <Route
                path="/login/successfull"
                exact
                component={LoginSuccessful}
              />
              <Route path="/auth/:action" exact component={Authenticate} />
              {/* need to discuss how will we handle it */}
              <Route
                path="*"
                render={() => (
                  <AuthWrapper>
                    <Switch>
                      <Route
                        path="/targeting-ai-playground-beta/:platform?"
                        component={TaiContextProvider}
                      />

                      <Route
                        path="/social-login"
                        exact
                        component={SocialLogin}
                      />
                      <Route
                        path="/select-accounts"
                        exact
                        component={SelectedAdAccountWrapper}
                      />
                      <Route
                        path="*"
                        render={() => (
                          <TMSWrapper
                            render={() => (
                              <Routes open={open} setOpen={setOpen} />
                            )}
                          />
                        )}
                      />
                    </Switch>
                  </AuthWrapper>
                )}
              />
            </Switch>
          </Auth0Provider>
        </Switch>
      </CompatRouter>
    </Router>
  );
};

export default App;
