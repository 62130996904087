import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import { CheckBox, StyledText } from "components/shared";
import React, { Fragment, useState } from "react";
import {
  CreativeRotationInitialState,
  GetBatchDetailsItemType,
  SetPreviewItem,
} from "store/types/ActionDebugger/CreativeRotation/creativeRotation.types";
import InHousePreviewModal from "./InHousePreviewModal";
import CRTooltipWithAdDetails from "./CRTooltipWithAdDetails";
import TableMetricComponent from "./TableMetricComponet";
import { crTimezone } from "utils/date.util";
import { getPreviewImage, intValue } from "../common.utils";
import { useSelector } from "react-redux";
import { getCurrencySymbol } from "utils/commonFunctions";
import { ReactComponent as StarIcon } from "../../../../assets/svg/star.svg";
import { ReactComponent as LoadingIcon } from "../../../../assets/svg/loading.svg";
import ThumbsUp from "../../../../assets/svg/thumbs_up.svg";
import ThumbsDown from "../../../../assets/svg/thumbs_down.svg";
import imagePlaceholder from "../../../../assets/png/no_image_placeholder.png";
import { getValueWithSign } from "./CRAccordionItem";

export default function CRTableRow({
  row,
  index,
  isItemSelected,
  handleClick,
  isExpired,
  imageLoading,
  imageInfo,
  assetColor,
}: {
  row: GetBatchDetailsItemType;
  index: number;
  isItemSelected: boolean;
  handleClick: any;
  isExpired?: boolean;
  imageLoading?: boolean;
  imageInfo?: SetPreviewItem;
  assetColor: string;
}) {
  const labelId = `enhanced-table-checkbox-${index}`;
  // const spendMetriCalc: any = (spend: string, cpa: string) => {
  //   const spendVal = intValue(spend);
  //   const cpaVal = intValue(cpa);
  //   if (spendVal >= 0 && cpaVal >= 0) {
  //     return {
  //       color: "red",
  //       direction: "up",
  //     };
  //   } else if (spendVal >= 0 && cpaVal < 0) {
  //     return {
  //       color: "green",
  //       direction: "up",
  //     };
  //   } else if (spendVal < 0 && cpaVal >= 0) {
  //     return {
  //       color: "green",
  //       direction: "down",
  //     };
  //   }

  //   return { color: "red", direction: "down" };
  // };

  const authState = useSelector((state: any) => state.auth);
  const [selectedPrew, setSelectedPrew] = useState("");

  const currency = authState?.accountDetails?.currency
    ? getCurrencySymbol(authState?.accountDetails?.currency)
    : "";

  return (
    <TableRow
      hover
      onClick={
        isExpired ||
        row.status !== "Recommended" ||
        row?.phase === "recommendation" ||
        row?.phase === "observation"
          ? () => {}
          : (event) => handleClick(event, row.recommendation_id)
      }
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={index}
      key={row.recommendation_id}
      selected={isItemSelected}
    >
      {/* Col1 */}
      <TableCell
        // component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "17% !important",
          minWidth: "17% !important",
          maxWidth: "17% !important",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: ".5em 0em",
            alignItems: "center",
            overflow: "hidden",
            // width: "17% !important",
            // maxWidth:'17% !important'
          }}
        >
          <CheckBox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            sx={{
              pointerEvents:
                isExpired || row.status !== "Recommended" ? "none" : "stroke",
            }}
          />
          {imageLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                height: "3em",
                width: "3em",
                borderRadius: "1.55981px",
              }}
            />
          ) : (
            <InHousePreviewModal
              previewLink={imageInfo?.adPreview?.[0]?.body}
              setSelectedPrew={setSelectedPrew}
              index={index}
            >
              <Box
                ml=".7em"
                component="img"
                src={
                  getPreviewImage(imageInfo?.adcreatives?.data[0]) ??
                  imagePlaceholder
                }
                sx={{
                  height: "3em",
                  width: "3em",
                  objectFit: "cover",
                  border: "0.133564px solid #E5E5E5",
                  borderRadius: "1.55981px",
                  zIndex:
                    selectedPrew === imageInfo?.adPreview?.[0]?.body
                      ? 1000
                      : undefined,
                }}
              />
            </InHousePreviewModal>
          )}
          <CRTooltipWithAdDetails
            data={{
              adName: row.asset_l0_ad_name,
              adId: row.asset_l0_ad_id,
              adsetName: row.parent.asset_l1_name,
              adsetId: row.parent.asset_l1_id,
              // adGroup:row.ad,
              // adGroupId:string;
              campaign: row.parent.asset_l2_name,
              campaignId: row.parent.asset_l2_id,
            }}
            assetColor={assetColor}
          />
        </Box>
      </TableCell>
      {/* Col2 */}
      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "12% !important",
          minWidth: "12% !important",
          maxWidth: "12% !important",
        }}
      >
        <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
          {row.action_to_do}
        </StyledText>
      </TableCell>
      {/* col2 */}

      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "12% !important",
          minWidth: "12% !important",
          maxWidth: "12% !important",
        }}
      >
        {" "}
        <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
          {row.action_basis}
        </StyledText>
      </TableCell>

      {/* col3 */}
      <TableCell
        align="center"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "12% !important",
          minWidth: "12% !important",
          maxWidth: "12% !important",
        }}
      >
        {row?.metrics?.spend_7day_avg === "-" ? (
          <StyledText fontSize="0.75em" lineHeight="15px">
            -
          </StyledText>
        ) : (
          // <TableMetricComponent
          //   title={`${currency}${row.metrics.spends.spends_abs}`}
          //   subtitle={Math.abs(intValue(row.metrics.spends.spends_rel))}
          //   {...spendMetriCalc(
          //     row.metrics.spends.spends_rel,
          //     row.metrics.cpa.cpa_rel ? row.metrics.cpa.cpa_rel : row.metrics.roas.roas_rel
          //   )}
          // />
            getValueWithSign({
            amount:row?.metrics?.spend_7day_avg,
            currency:getCurrencySymbol(authState?.accountDetails?.currency) || '$',
            type:'spend',
            batchMetrics:row?.metrics
            })
        )}
      </TableCell>
      {/* Col4 */}
      <TableCell
        align="center"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "9% !important",
          minWidth: "9% !important",
          maxWidth: "9% !important",
        }}
      >
        {row?.metrics?.[`${row.metric_type}_7day_avg`]=== "-"? (
          <StyledText fontSize="0.75em" lineHeight="15px">
            -
          </StyledText>
        ) : (
          // <TableMetricComponent
          //   title={`${currency}${row.metrics.cpa.cpa_abs}`}
          //   subtitle={Math.abs(intValue(row.metrics.cpa.cpa_rel))}
          //   color={intValue(row.metrics.cpa.cpa_rel) >= 0 ? "red" : "green"}
          //   direction={intValue(row.metrics.cpa.cpa_rel) >= 0 ? "up" : "down"}
          // />
          getValueWithSign({
            amount:row?.metrics?.[`${row.metric_type}_7day_avg`],
             type:row.metric_type,
             batchMetrics:row?.metrics
             })
          )}
          {/* {!row.metrics.cpa?.cpa_abs && (!row.metrics.roas?.roas_abs  ? (
            <StyledText fontSize="0.75em" lineHeight="15px">
              -
            </StyledText>
          ) : (
            <TableMetricComponent
              title={`${currency}${ parseFloat(row.metrics.roas.roas_abs).toFixed(2)}`}
              subtitle={Math.abs(intValue(row.metrics.roas.roas_rel))}
              color={intValue(row.metrics.roas.roas_rel)== 0 ? "black" : intValue(row.metrics.roas.roas_rel) > 0 ? "green" : "red"}
              direction={intValue(row.metrics.roas.roas_rel) ==  0 ? "no arrow" : intValue(row.metrics.roas.roas_rel) >  0 ? "up" : "down"}
            />
          ))} */}
      </TableCell>
      
      {/* col5 */}
      <TableCell
        align="center"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "9% !important",
          minWidth: "9% !important",
          maxWidth: "9% !important",
        }}
      >
        {row?.metrics?.frequency_7day_avg === "-" ? (
          <StyledText fontSize="0.75em" lineHeight="15px">
            -
          </StyledText>
        ) : (
          // <TableMetricComponent
          //   title={row.metrics.frequency.frequency_abs}
          //   subtitle={Math.abs(intValue(row.metrics.frequency.frequency_rel))}
          //   color={
          //     intValue(row.metrics.frequency.frequency_rel) < 0
          //       ? "green"
          //       : "red"
          //   }
          //   direction={
          //     intValue(row.metrics.frequency.frequency_rel) < 0 ? "down" : "up"
          //   }
          // />
          getValueWithSign({
            amount:row?.metrics?.frequency_7day_avg,
            type:'frequency',
            batchMetrics:row?.metrics})
        )}
      </TableCell>

      {/* col6 */}
      <TableCell
        align="center"
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "9% !important",
          minWidth: "9% !important",
          maxWidth: "9% !important",
        }}
      >
        {row?.metrics?.ctr_7day_avg === "-" ? (
          <StyledText fontSize="0.75em" lineHeight="15px">
            -
          </StyledText>
        ) : (
          // <TableMetricComponent
          //   title={row.metrics.engagement.engagement_abs}
          //   subtitle={Math.abs(intValue(row.metrics.engagement.engagement_rel))}
          //   color={
          //     intValue(row.metrics.engagement.engagement_rel) < 0
          //       ? "red"
          //       : "green"
          //   }
          //   direction={
          //     intValue(row.metrics.engagement.engagement_rel) < 0
          //       ? "down"
          //       : "up"
          //   }
          // />
          getValueWithSign({
            amount:row?.metrics?.ctr_7day_avg,
            type:'ctr',
            batchMetrics:row?.metrics})
        )}
      </TableCell>
      {/* col7 */}
      <TableCell
        sx={{
          borderRight: "1px solid #e5e5e5",
          width: "10% !important",
          minWidth: "10% !important",
          maxWidth: "10% !important",
        }}
        align={row.updated_at ? "left" : "center"}
      >
        <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
          {(row.updated_at && row.updated_at!=='-')
            ? crTimezone(row.updated_at)
            : (row.updated_at || '-')}
        </StyledText>
      </TableCell>
      {/* col8 */}
      <TableCell align="left" sx={{
        width:'10% !important',
        minWidth:'10% !important',
        maxWidth:'10% !important'
        }}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            sx={{
              alignItems: "center",
            }}
          >
            <StyledText fontSize="0.75em" lineHeight="15px" color="#33343D">
              {row.status === "Recommended" && isExpired
                ? "No Action Taken"
                : row.status}
            </StyledText>
            {row.status === "Recommended" && !isExpired && <StarIcon />}
            {row.status === "Executed" && (
              <img
                src={ThumbsUp}
                alt="thumbs up"
                style={{
                  marginLeft: ".4em",
                  width: "1em",
                }}
              />
            )}
            {row.status === "Rejected" && (
              <img
                src={ThumbsDown}
                alt="thumbs down"
                style={{
                  marginLeft: ".4em",
                  width: "1em",
                }}
              />
            )}
            {row.status === "Processing" && (
              <LoadingIcon
                style={{
                  marginLeft: ".5em",
                }}
              />
            )}
          </Box>
          {row.status === "Rejected" && row?.message?.length ? (
            <StyledText
              fontSize="0.625em"
              lineHeight="12px"
              color="#6A6A6A"
              fontWeight={400}
              style={{wordWrap:'break-word'}}
            >
              ({row.message})
            </StyledText>
          ) : (
            <Fragment />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}
