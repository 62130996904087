import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PublishModalClose } from "../../../assets/svg/PublishModalClose.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as TaiDropdownCheckmark } from "../../../assets/tai/taiDropdownCheckmark.svg";
import { ReactComponent as NoDataPublishExclusions } from "../assets/svgs/NoDataPublishExclusions.svg";
import { ReactComponent as NoCountPublishExclusions } from "../assets/svgs/NoCountPublishExclusions.svg";
import { ReactComponent as Search } from "../assets/svgs/search.svg";

import {
  getAdAccounts,
  setSelectedCohortsForPublish,
  storeTargetingAICohort,
} from "../../../store/actions/targetingAI";
import CustomAccordian from "../common/CustomAccordian";

import "./PublishModal.scss";
import LoaderModal from "../common/LoaderModal";
import { getMultipleAdAccountDetails } from "../../../store/actions/adaccount";
import {
  getCurrencySymbol,
  isViewerRole,
  isEditorRole,
  getRolesHierarchy,
} from "../../../utils/commonFunctions";
import { mixPanelAdEvent } from "../../../utils/mixpanel";
import Tooltip from "../../../ui/Tooltip/Tooltip";
import { TaiContext } from "../TaiContextProvider";
import EditAdsetModal from "./EditAdsetModal";
import { Modal, TextField, InputAdornment } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { getAdsetDetails } from "store/actions/adaccount";
import { LightTooltip } from "components/CreativeAI_Revamp/shared";
import { brandViewerAccessText } from "utils/constants/commonConsts";
import { SelectedCohortTypeEnum } from "store/types/AudienceManager";
import { RolesWrapper } from "utils/rolesWrapper";
import { useSearchParams } from "react-router-dom-v5-compat";

function CheckboxStripList({
  list,
  selectedValues,
  setSelectedValues,
  adAccountId,
}) {
  return (
    <div
      className="m-2"
      style={{
        maxHeight: "10rem",
        overflowY: "auto",
      }}
    >
      {list && list.length > 0 ? (
        list.map((item) => {
          const isSelected =
            selectedValues[adAccountId].adset &&
            selectedValues[adAccountId].adset.id === item.id;
          return (
            <button
              key={item.id}
              className={`d-flex w-100 my-1 rounded text-left checkbox-strip ${
                isSelected ? "selected" : ""
              }`}
              onClick={() => {
                let newSelection = { ...selectedValues };
                if (isSelected) {
                  delete newSelection[adAccountId].adset;
                } else {
                  newSelection = {
                    ...newSelection,
                    [adAccountId]: {
                      ...newSelection[adAccountId],
                      adset: item,
                    },
                  };
                }
                setSelectedValues(newSelection);
              }}
            >
              <div
                style={{
                  width: "5%",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  height: "24px",
                }}
              >
                <Box
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "8px",
                    background: `${
                      item.effective_status === "ACTIVE" ? "#28B49B" : "#ABABAB"
                    }`,
                  }}
                ></Box>
              </div>
              <div
                style={{
                  width: "90%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                }}
                title={item.name}
              >
                {item.name}
              </div>
              <div
                style={{
                  width: "5%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  height: "24px",
                }}
              >
                {isSelected && <TaiDropdownCheckmark />}
              </div>
            </button>
          );
        })
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          Nothing to show
        </div>
      )}
    </div>
  );
}
function CheckboxStripListFoExclusions({ list }) {
  return (
    <div
      className="m-2"
      style={{
        maxHeight: "10rem",
        overflowY: "auto",
      }}
    >
      {list && list.length > 0 ? (
        list.map((item) => {
          return (
            <Box
              key={item}
              className={`d-flex w-100 my-1 rounded text-left checkbox-strip `}
              sx={{ opacity: 0.5 }}
            >
              <div
                style={{
                  width: "95%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflowX: "hidden",
                }}
                title={item}
              >
                {item}
              </div>
            </Box>
          );
        })
      ) : (
        <Box className="d-flex flex-column align-items-center justify-content-center h-100">
          <NoCountPublishExclusions />
          <Typography className="font-10 font-400">
            No Recommendations
          </Typography>
        </Box>
      )}
    </div>
  );
}

function RadioStrip({ selectedValues, setSelectedValues, adAccountId }) {
  let isSelected = selectedValues && selectedValues[adAccountId].radio;
  return (
    <div className="px-2 py-3 w-100 size-14">
      <div className="my-2 font-body weight-500" style={{ width: "100%" }}>
        Publishing Type
      </div>
      <div
        className="gap-2 py-2 text-black d-flex justify-space-between"
        style={{ width: "100%" }}
      >
        <div className="d-flex ">
          <input
            type="radio"
            name={adAccountId}
            className="mr-2"
            checked={isSelected && selectedValues[adAccountId].radio === 1}
            onChange={() => {
              setSelectedValues({
                ...selectedValues,
                [adAccountId]: {
                  ...selectedValues[adAccountId],
                  radio: 1,
                },
              });
            }}
          />
          <span>Existing Campaign</span>
        </div>
        <div className="d-flex " style={{ cursor: "not-allowed" }}>
          <input
            type="radio"
            name={adAccountId}
            disabled={true}
            className="mr-2"
            checked={isSelected && selectedValues[adAccountId].radio === 2}
            onChange={() => {
              setSelectedValues({
                ...selectedValues,
                [adAccountId]: {
                  ...selectedValues[adAccountId],
                  radio: 2,
                },
              });
            }}
          />
          <span style={{ opacity: 0.3 }}>Add to New Campaign</span>
        </div>
      </div>
    </div>
  );
}

function PublishModal({
  opendFrom = "playground",
  setShowPublishAudienceManagerModal = (isShowModal) => undefined,
  setShowAudiencePublishSuccessModal = (isShow) => undefined,
  setIsQuickPublishClicked = (isClicked) => undefined,
  handleSuccessResponse = () => undefined,
  showPublishAudienceManagerModal = false,
  deleteCohortIds = [],
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = searchParams.get("beta");
  const cohorts = useSelector((state) => state.cohorts);
  const aiInitiation = useSelector((state) => state.aiInitiation.apiData);
  const audienceManager = useSelector((state) => state.audienceManager);
  const calibrationData = useSelector((state) => state.calibration.formData);
  const { selectedBrand, brandRoles } = useSelector((state) => state.adaccount);
  const { selectedAgency } = useSelector((state) => state.common);
  const auth = useSelector((state) => state.auth);
  const { cohortType } = useContext(TaiContext);
  const aiGroupId =
    opendFrom === "playground"
      ? aiInitiation?.aiGroup?.id
      : audienceManager?.selectedAiGroup?.id;
  const aiGroupName =
    opendFrom === "playground"
      ? aiInitiation?.aiGroup?.name
      : audienceManager?.selectedAiGroup?.name;
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [loadingText, setLoadingText] = useState(
    "Reference adsets are been searched"
  );

  const [adAccounts, setAdAccounts] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [disabledMessage, setDisabledMessage] = useState([]);
  // const isBrandViewer = useMemo(
  //   () => brandRoles?.length === 1 && brandRoles[0]?.role === "brand-viewer",
  //   [brandRoles]
  // );

  useEffect(() => {
    dispatch(
      getAdAccounts(
        { aiGroupId, platformId: "1c59a6e2-843b-4634-92a0-6fa93ce6c761" },
        (adAccounts, error) => {
          if (!error && adAccounts.data) {
            let adaccountIds = adAccounts.data.map(
              (adAccount) => adAccount.accountId
            );
            dispatch(
              getMultipleAdAccountDetails(
                { adaccountIds, platformType: "facebook" },
                (result, error) => {
                  if (!error) {
                    let defaultSelectedValues = {};
                    dispatch(
                      getAdsetDetails(
                        { group_id: aiGroupId, user: {} },
                        (resp) => {
                          let adsets = resp.data;

                          setAdAccounts(
                            adAccounts.data.map((adAccount, index) => {
                              const symbol = getCurrencySymbol(
                                result[index]?.currency
                              );

                              defaultSelectedValues[adAccount.accountId] = {
                                adAccountName: adAccount.adAccountName,
                                radio: 1,
                                budget: adAccount.adsetBudget || null,
                                symbol,
                              };
                              adAccount.adsets = adAccount.adsets.map(
                                (adset) => {
                                  return {
                                    ...adset,
                                    recommendedExclusions: adsets.find(
                                      (a) => a.adsetId === adset.id
                                    )?.recommendedExclusions,
                                  };
                                }
                              );
                              return {
                                ...adAccount,
                                symbol,
                                currency: result[index].currency,
                              };
                            })
                          );

                          setSelectedValues(defaultSelectedValues);
                          setLoading(false);
                        }
                      )
                    );
                  } else {
                    setLoading(false);
                  }
                }
              )
            );
          } else {
            setLoading(false);
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    let message = "Select Reference Adset To Proceed";

    let findAllTrue = Object.keys(selectedValues).filter((key) => {
      if (selectedValues[key].adset) {
        let check =
          selectedValues[key].budget > 0 && selectedValues[key].budget !== "";
        message = check ? "" : "Set Adset Budget To Proceed";
        return check;
      }
      return false;
    });
    setDisabledMessage(findAllTrue ? message : "");
    setSelectedKeys(findAllTrue);
  }, [selectedValues]);

  const onConfigureClick = (e) => {
    e.preventDefault();
    setShowConfigureModal(true);
  };
  const mixPanelEventForTriggeringPublishEvent = () => {
    if (audienceManager.selectedCohortType === SelectedCohortTypeEnum.drafted) {
      mixPanelAdEvent("Publish through saved", {
        ai_group_name: audienceManager?.selectedAiGroup?.name,
        ai_group_id: audienceManager?.selectedAiGroup?.id,
        agency_id: selectedAgency?.id,
        agency_name: selectedAgency?.name,
        adaccount_name: audienceManager?.selectedAiGroup?.adAccounts,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        module_name: "TAI Audience Manager",
      });
    } else if (
      audienceManager.selectedCohortType === SelectedCohortTypeEnum.recommended
    ) {
      mixPanelAdEvent("Publish through Pixis recommended", {
        ai_group_name: audienceManager?.selectedAiGroup?.name,
        ai_group_id: audienceManager?.selectedAiGroup?.id,
        adaccount_name: audienceManager?.selectedAiGroup?.adAccounts,
        agency_name: selectedAgency?.name,
        agency_id: selectedAgency?.id,
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        module_name: "TAI Audience Manager",
      });
    }
  };
  const onPublishClick = (e, calibrationType) => {
    e.preventDefault();
    setLoadingText("The audiences are being published");
    setLoading(true);
    const apiData = selectedKeys.reduce(
      (prev, key) => {
        const { adset, radio, budget, adAccountName, symbol } =
          selectedValues[key];
        let campaignId = radio === 2 ? adset.campaign_id : null;
        let action_list = [];

        action_list = cohorts.map(
          ({
            cohort_name,
            cohort_id,
            brandId,
            brand_name,
            brand_website,
            type,
            targeting,
            explore,
            exploit,
            facebook_json,
            calibrationData,
            icp_context_description,
          }) => {
            if (type && type === "ICP") {
            // as per requirements of https://pyxispm.atlassian.net/browse/OPG-1707
            delete targeting['geo_locations']
              return {
                budget,
                fb_ad_account_id: key,
                reference_adset_id: adset.id,
                reference_campaign_id: campaignId,
                adAccountName,
                adsetName: adset.name,
                adset_campaign_id: adset.campaign_id,
                cohort_name,
                cohort_id,
                campaignName: adset.campaignName ? adset.campaignName : "",
                new_adset_name:
                  adset.cohorts &&
                  adset.cohorts[cohort_id] &&
                  adset.cohorts[cohort_id].new_adset_name
                    ? `${adset.cohorts[cohort_id].new_adset_name}_TAI_${cohort_name}`
                    : undefined,
                brandId: "",
                brand_name,
                brand_website,
                targeting,
                type,
                explore,
                exploit,
                icp_context_description,
              };
            } else {
              return {
                data: { facebook_json },
                budget,
                fb_ad_account_id: key,
                reference_adset_id: adset.id,
                reference_campaign_id: campaignId,
                adAccountName,
                adsetName: adset.name,
                adset_campaign_id: adset.campaign_id,
                cohort_name,
                cohort_id,
                campaignName: adset.campaignName ? adset.campaignName : "",
                new_adset_name:
                  adset.cohorts &&
                  adset.cohorts[cohort_id] &&
                  adset.cohorts[cohort_id].new_adset_name
                    ? `${adset.cohorts[cohort_id].new_adset_name}_TAI_${cohort_name}`
                    : undefined,
                ...calibrationData,
                brandId: "",
                brand_name,
                brand_website,
              };
            }
          }
        );
        prev.email_data.selected_accounts.push({
          adAccountId: key,
          adsetId: adset.id,
        });
        prev.event_data.publish_data.push({
          ad_account_name: adAccountName,
          ad_account_id: key,
          reference_ad_set_name: adset.name,
          reference_ad_set_id: adset.id,
          publishing_type: radio === 1 ? "Existing" : "New",
          Adset_budget: `${symbol}${budget}`,
        });

        return {
          ...prev,
          action_list: [...prev.action_list, ...action_list],
        };
      },
      {
        action_list: [],
        email_data: {
          type: calibrationType,
          selected_accounts: [],
          aiGroupId,
          cohorts,
          aigroup_name: aiGroupName,
          brandId: selectedBrand?.id,
          brand_name: selectedBrand?.name,
          brand_website: selectedBrand?.brandWebsite,
        },
        event_data: {
          module_name: "Facebook Playground",
          brand_name: opendFrom === "playground" ? selectedBrand.name : "",
          agency_name: selectedAgency?.name,
          agencyId: selectedAgency?.id,
          ai_group_name: aiGroupName,
          ai_group_id: aiGroupId,
          publish_data: [],
        },
        delete_cohorts: deleteCohortIds,
      }
    );
    dispatch(
      storeTargetingAICohort(
        {
          ...apiData,
          user: auth.user,
        },
        (res, err) => {
          if (!err) {
            dispatch(setSelectedCohortsForPublish([]));
            handleSuccessResponse(apiData.delete_cohorts);
            setShowAudiencePublishSuccessModal(true);
            setShowPublishAudienceManagerModal(false);
            mixPanelEventForTriggeringPublishEvent();
          }
          setLoading(false);
        }
      )
    );
    mixPanelAdEvent("TAI Cohorts Published", {
      module_name: "Facebook Playground",
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedAgency?.id,
      agency_name: selectedAgency?.name,
      ai_group_name: aiGroupName,
      ai_group_id: aiGroupId,
      number_of_cohorts: cohorts?.length,
      cohort_details: cohorts,
      ad_account_id: aiInitiation?.adAccount?.id,
      ad_account_name: aiInitiation?.adAccount?.name,
      tai_flow: cohortType,
    });
  };

  // const storedData = localStorage.getItem("taiExclusions")
  //   ? JSON.parse(localStorage.getItem("taiExclusions"))
  //   : {};

  return (
    <>
      {loading ? (
        <LoaderModal text={loadingText} loading={loading} />
      ) : (
        <Modal open={showPublishAudienceManagerModal}>
          <>
            {showConfigureModal ? (
              <EditAdsetModal
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setShowModal={setShowPublishAudienceManagerModal}
                setShowConfigureModal={setShowConfigureModal}
                showConfigureModal={showConfigureModal}
              />
            ) : (
              <div className="position-absolute translate-middle top-50 start-50 font-body">
                <div
                  className="bg-white rounded"
                  style={{
                    border: "1px solid #5E9EFE",
                    boxShadow: "0px 0.5rem 0.75rem rgba(0, 31, 79, 0.08)",
                    width: "50rem",
                    minHeight: `${isViewedFromBeta ? '37rem' : '40rem'}`,
                    maxHeight: "44.375rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="px-3 py-2 heading d-flex align-items-center justify-content-between font-lato w-100"
                    style={{
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                      borderRadius: "0.625rem 0.625rem 0px 0px",
                      height: "10%",
                    }}
                  >
                    <div className="d-flex flex-column align-items-start">
                      <div
                        className="weight-500"
                        style={{
                          fontSize: "1.125rem",
                        }}
                      >
                        Select Reference Adset
                      </div>
                      <div
                        style={{
                          color: "#999999",
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          fontStyle: "italic",
                        }}
                      >
                        Note: Each cohort is published as a new ad set by
                        duplicating the selected reference ad set
                      </div>
                    </div>
                    <button
                      className="p-0 text-black bg-transparent border-0"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setSelectedCohortsForPublish([]));
                        setShowPublishAudienceManagerModal(false);
                        if (opendFrom === "audienceManager") {
                          setIsQuickPublishClicked(false);
                        }
                      }}
                    >
                      <PublishModalClose />
                    </button>
                  </div>
                  <div className="px-3 mt-3 body" style={{ height: "80%" }}>
                    <div className="mb-2 d-flex align-items-center font-lato">
                      <span style={{ fontSize: "1.125rem" }}>Meta</span>
                      <Info
                        className="ml-2"
                        style={{ width: "1rem", height: "1rem" }}
                      />
                    </div>
                    <div
                      className="publish-accordion"
                      style={{
                        fontSize: "0.875rem",
                        color: "#666666",
                        overflowY: "scroll",
                        maxHeight: "35rem",
                      }}
                    >
                      <CustomAccordian
                        cards={adAccounts.map((adAccount) => ({
                          label: "Select Reference Adset",
                          additional: (
                            <>
                              <div className="text-dove-gray">
                                Account Name: {adAccount.adAccountName}
                              </div>
                            </>
                          ),
                          description: (
                            <>
                              <hr className="mx-2 my-0" />
                              <Grid
                                container
                                sx={{
                                  p: 2,
                                }}
                              >
                                <Grid
                                  item
                                  xs={7}
                                  sx={{
                                    border: "1px solid #F0F0F0",
                                    borderTopLeftRadius: "0.5rem",
                                    borderBottomLeftRadius: "0.5rem",
                                    py: 2,
                                  }}
                                >
                                  <Box
                                    style={{ display: "flex" }}
                                    className="mx-2 my-0"
                                  >
                                    <Box
                                      sx={{
                                        width: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Select Reference Ad Set
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        width: "50%",
                                      }}
                                    >
                                      {!showSearch ? (
                                        <Box
                                          onClick={() => setShowSearch(true)}
                                          sx={{
                                            height: "32px",
                                            width: "32px",
                                            border: "1px solid #F0F0F0",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Search />
                                        </Box>
                                      ) : (
                                        <TextField
                                          onChange={(e) => {
                                            setSearchVal(e.target.value);
                                          }}
                                          sx={{
                                            width: "200px",
                                            float: "right",
                                            "& .MuiOutlinedInput-input": {
                                              padding: 0,
                                              height: "36px",
                                            },
                                            "& .MuiOutlinedInput-input::placeholder":
                                              {
                                                fontFamily: "Inter",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                textAlign: "left",
                                                color: "#969696",
                                                paddingTop: "50px",
                                              },
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <Search />
                                              </InputAdornment>
                                            ),
                                          }}
                                          placeholder={"Search"}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                  <CheckboxStripList
                                    list={
                                      searchVal
                                        ? adAccount?.adsets?.filter((item) =>
                                            item.name
                                              .toLowerCase()
                                              .includes(searchVal.toLowerCase())
                                          )
                                        : adAccount.adsets
                                    }
                                    selectedValues={selectedValues}
                                    setSelectedValues={setSelectedValues}
                                    adAccountId={adAccount.accountId}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  sx={{
                                    border: "1px solid #F0F0F0",
                                    borderTopRightRadius: "0.5rem",
                                    borderBottomRightRadius: "0.5rem",
                                    borderLeft: "none",
                                    py: 2,
                                  }}
                                >
                                  <Box className="mx-2 my-0">
                                    Recommended Exclusions
                                  </Box>
                                  {selectedValues[adAccount.accountId].adset ? (
                                    <CheckboxStripListFoExclusions
                                      list={
                                        selectedValues[adAccount.accountId]
                                          .adset.recommendedExclusions
                                          ? selectedValues[
                                              adAccount.accountId
                                            ].adset.recommendedExclusions.split(
                                              ","
                                            )
                                          : []
                                      }
                                      selectedValues={selectedValues}
                                      setSelectedValues={setSelectedValues}
                                      adAccountId={adAccount.accountId}
                                    />
                                  ) : (
                                    <Box className="d-flex flex-column align-items-center justify-content-center h-100">
                                      <NoDataPublishExclusions />
                                      <Typography className="font-10 font-400">
                                        Please select Reference Ad Set
                                      </Typography>
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                              <hr className="mx-2 my-0 " />
                              <Grid
                                container
                                sx={{ p: 2 }}
                                justifyContent="space-between"
                              >
                                <Grid
                                  item
                                  xs={6.75}
                                  sx={{
                                    border: "1px solid #F0F0F0",
                                    borderRadius: "0.5rem",
                                  }}
                                >
                                  <RadioStrip
                                    selectedValues={selectedValues}
                                    setSelectedValues={setSelectedValues}
                                    adAccountId={adAccount.accountId}
                                  />
                                </Grid>
                                <Grid item xs={0.25}></Grid>
                                <Grid
                                  item
                                  xs={5}
                                  sx={{
                                    border: "1px solid #F0F0F0",
                                    borderRadius: "0.5rem",
                                  }}
                                >
                                  <Stack className="px-2 w-100 d-flex size-14 ">
                                    <div
                                      className="py-3 font-body weight-500"
                                      style={{ width: "100%" }}
                                    >
                                      Adset Budget*
                                    </div>

                                    <div
                                      className="input-group"
                                      style={{ width: "50%" }}
                                    >
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text text-dove-gray"
                                          style={{
                                            background: "#FCFCFC",
                                          }}
                                        >
                                          {adAccount.symbol &&
                                          adAccount.symbol.length === 1
                                            ? adAccount.symbol
                                            : "$"}
                                        </div>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="inlineFormInputGroup"
                                        placeholder="-"
                                        value={
                                          selectedValues[adAccount.accountId] &&
                                          selectedValues[adAccount.accountId]
                                            .budget
                                            ? selectedValues[
                                                adAccount.accountId
                                              ].budget
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setSelectedValues({
                                            ...selectedValues,
                                            [adAccount.accountId]: {
                                              ...selectedValues[
                                                adAccount.accountId
                                              ],
                                              budget: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </Stack>
                                </Grid>
                              </Grid>

                              <hr className="mx-2 my-0 " />
                            </>
                          ),
                          selected:
                            selectedValues[adAccount.accountId] &&
                            selectedValues[adAccount.accountId].radio &&
                            selectedValues[adAccount.accountId].adset ? (
                              <div className="p-2" style={{ width: "95%" }}>
                                <div className="my-1 d-flex w-100 text-scorpion">
                                  <div style={{ width: "5%" }}>
                                    <TaiDropdownCheckmark />
                                  </div>
                                  <div
                                    style={{
                                      width: "95%",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflowX: "hidden",
                                    }}
                                    title={
                                      selectedValues[adAccount.accountId].adset
                                        .name
                                    }
                                  >
                                    {
                                      selectedValues[adAccount.accountId].adset
                                        .name
                                    }
                                  </div>
                                </div>
                                <div className="py-1 w-100 d-flex size-14">
                                  <div
                                    className="weight-500"
                                    style={{ width: "25%" }}
                                  >
                                    Publishing Type :
                                  </div>
                                  <div
                                    className="d-flex align-items-center text-dove-gray"
                                    style={{ width: "70%" }}
                                  >
                                    <span>
                                      {selectedValues[adAccount.accountId]
                                        .radio === 1
                                        ? "Existing Campaign"
                                        : "Add to New Campaign"}
                                    </span>
                                  </div>
                                </div>
                                <div className="py-1 w-100 d-flex size-14">
                                  <div
                                    className="weight-500"
                                    style={{ width: "22%" }}
                                  >
                                    Adset Budget :
                                  </div>
                                  <div
                                    className="d-flex align-items-center text-dove-gray"
                                    style={{ width: "70%" }}
                                  >
                                    <span>
                                      {adAccount.symbol &&
                                      adAccount.symbol.length === 1
                                        ? adAccount.symbol
                                        : "$"}
                                      {selectedValues[adAccount.accountId]
                                        .budget &&
                                      selectedValues[adAccount.accountId]
                                        .budget !== ""
                                        ? selectedValues[adAccount.accountId]
                                            .budget
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : null,
                          key: adAccount.accountId,
                        }))}
                      />
                    </div>
                  </div>
                  <div
                    className="pt-2 pb-2 action-btns d-flex justify-content-between align-items-center"
                    style={{
                      boxShadow: "0px 0px 2px rgba(30, 21, 83, 0.2)",
                      borderRadius: "0px 0px 0.625rem 0.625rem",
                      marginTop: "auto",
                      height: "10%",
                    }}
                  >
                    <button
                      className="btn link"
                      style={{
                        textDecoration: "underline",
                        color: "#0869FB",
                      }}
                      disabled={disabledMessage !== ""}
                      onClick={onConfigureClick}
                    >
                      Configure New Ad Set(s)
                    </button>
                    {disabledMessage !== "" ? (
                      <Tooltip
                        className="tooltip-top"
                        tooltipContainer={
                          <button
                            className="text-white btn primary"
                            style={{
                              padding: "0 !important",
                              marginTop: "auto",
                            }}
                            disabled={true}
                          >
                            Publish
                          </button>
                        }
                      >
                        {disabledMessage}
                      </Tooltip>
                    ) : (
                      // <LightTooltip
                      //   // title={isBrandViewer ? brandViewerAccessText : ""}
                      //   title={isViewerRole(brandRoles) ? brandViewerAccessText : ""}
                      //   placement="top-end"
                      // >
                      <RolesWrapper
                        disabled={
                          getRolesHierarchy(brandRoles) === "viewer" ||
                          getRolesHierarchy(brandRoles) === "editor"
                        }
                      >
                        <button
                          className="text-white btn primary"
                          style={{
                            padding: "0 !important",
                            marginTop: "auto",
                            // cursor:
                            //   // isBrandViewer ||
                            //   isViewerRole(brandRoles) ||
                            //   isEditorRole(brandRoles)
                            //     ? "not-allowed !important"
                            //     : "pointer",
                          }}
                          // disabled={
                          //   // isBrandViewer ||
                          //   // isViewerRole(brandRoles) ||
                          //   // isEditorRole(brandRoles)
                          // }
                          // onClick={(e) => {
                          //   if (
                          //     // !isBrandViewer ||
                          //     !isViewerRole(brandRoles) ||
                          //     !isEditorRole(brandRoles)
                          //   ) {
                          //     onPublishClick(e, calibrationData.TypeOfCohort);
                          //   }
                          // }}
                          onClick={(e) => {
                            onPublishClick(e, calibrationData.TypeOfCohort);
                          }}
                        >
                          Publish
                        </button>
                      </RolesWrapper>
                      // </LightTooltip>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        </Modal>
      )}
    </>
  );
}

export default PublishModal;
